import { RichTextSchema } from "@storyblok/vue";

let emoji;
let emojiMap = new Map();
let nodeEmojiLoaded = false;
let emojiLibLoaded = false;

async function loadNodeEmoji() {
  if (!nodeEmojiLoaded) {
    emoji = await import("node-emoji");
    nodeEmojiLoaded = true;
    console.log("loadNodeEmoji:Loaded");
  }
}

async function loadEmojiLib() {
  if (!emojiLibLoaded) {
    const emojilib = await import("emojilib");
    for (const [emojiKey, terms] of Object.entries(emojilib.default)) {
      for (const term of terms) {
        emojiMap.set(term, emojiKey);
      }
    }
    emojiLibLoaded = true;
  }
}

const CustomRichTextSchema = computed(() => {
  RichTextSchema.marks.link = (node) => {
    if (!node.attrs) {
      return {
        tag: "",
      };
    }

    const isEmailLinkType = (type: string) => type === "email";

    const escapeHTML = function (string: string) {
      const htmlEscapes = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;",
      };

      const reUnescapedHtml = /[&<>"']/g;
      const reHasUnescapedHtml = RegExp(reUnescapedHtml.source);

      return string && reHasUnescapedHtml.test(string) ? string.replace(reUnescapedHtml, (chr) => htmlEscapes[chr]) : string;
    };
    const attrs = { ...node.attrs };
    const { linktype = "url" } = node.attrs;
    delete attrs.linktype;

    if (attrs.href) {
      attrs.href = escapeHTML(node.attrs.href || "");

      if (!attrs.href.startsWith("http://") && !attrs.href.startsWith("https://") && !attrs.href.startsWith("mailto:") && !attrs.href.startsWith("tel:")) {
        attrs.href = "/" + attrs.href.replace(/^\/+/, "");
      }
    }

    if (isEmailLinkType(linktype)) {
      attrs.href = `mailto:${attrs.href}`;
    }

    if (attrs.anchor) {
      attrs.href = `${attrs.href}#${attrs.anchor}`;
      delete attrs.anchor;
    }

    if (attrs.custom) {
      for (const key in attrs.custom) {
        attrs[key] = attrs.custom[key];
      }
      delete attrs.custom;
    }

    return {
      tag: [
        {
          tag: "a",
          attrs: attrs,
        },
      ],
    };
  };

  RichTextSchema.nodes.emoji = async (node) => {
    await loadNodeEmoji();
    let emojiChar = emoji.get(node.attrs.name);

    if (!emojiChar) {
      await loadEmojiLib();
      emojiChar = emojiMap.get(node.attrs.name);
    }

    if (emojiChar) {
      node.content = [
        {
          text: emojiChar,
          type: "text",
        },
      ];
      return;
    }

    node.content = [
      {
        text: "",
        type: "text",
      },
    ];
    return;
  };

  return RichTextSchema;
});

export const useRenderRichText = (content: any) => {
  if (Object.values(content).length === 0) return "";
  return renderRichText(content, {
    schema: CustomRichTextSchema.value,
    resolver: (component, blok) => {
      const blokString = JSON.stringify(blok).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#39;");
      return `<component :blok='${blokString}' is="content-${component}" />`;
    },
  });
};
